@media print
{    
    .print-hide, .print-hide *
    {
        display: none !important;
    }
}

.page-break {
	page-break-after: always;
}

.report {
	background-color: #FFF0;
	color: black;
}

.banner {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	padding: 3vw 3vh;
}

.banner .logo {
	position: sticky;
	display: inline-block;
	left: 50%;
	top: 0;
	width: 40vw;
	transform: translate(-50%);
}
.banner .print.button {
  display: inline-block;
  padding: 0.75vh 1.5vw;
	background-color: #FFF;
	border: 0.3rem solid var(--main-color);
	border-radius: 4rem;
	font-size: 3vw;
	font-weight: bold;
}
.banner .print.button img {
	width: 2.5vw;
	padding-right: 0.5vw;
}


.banner .sharedoc.button {
	display: inline-block;
	padding: 0.75vh 1.5vw;
	background-color: rgb(206 0 0);
	border: 0.75rem outset red;
	border-radius: 4rem;
	color: white;
	font-size: 3.3vw;
}
.banner .sharedoc.form {
	display: inline-block;
	padding: 0.75vh 1.5vw;
	border-radius: 4rem;
	font-size: 2vw;
}
.banner .sharedoc.form input {
	display: inline-block;
	border: 0.25rem outset red;
	border-radius: 4rem;
	font-size: 3vw;
}
.banner .sharedoc.form button {
	display: inline-block;
	padding: 0.75vh 1.5vw;
	background-color: rgb(206 0 0);
	border: 0.25rem outset red;
	border-radius: 3rem;
	color: white;
	font-size: 2.5vw;
}

.banner .sharedoc.follower {
	display: inline-block;
	padding: 0.75vh 1.5vw;
	background-color: rgb(255, 255, 255);
	border: 0.75rem outset greenyellow;
	border-radius: 1rem;
	color: rgb(0, 0, 0);
	font-size: 3.3vw;
}


.banner .user.name {
	font-size: 3.3vw;
	vertical-align: middle;
	margin-left: 3vw;
}


.report.date {
	padding: 1vh 0 0 4vw;
}

.report.date img {
	margin-top: -1vh;
	height: 50px;
}

.report.date span {
	font-size: 2vw;
	padding-left: 1vw;
	font-size: 3vw;
}


.reports {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center;
	padding: 1vw 0vh;
}

.mini.report {
	height: 20%;
}


.mini.chart.outside-container {
	background-color: #FFF0;
	border: 0.4rem solid #666;
	border-radius: 2rem;
	margin: 1vw 1vh;
	padding: 0;
	break-inside: avoid !important;
}

.mini.chart.inside-container {
	break-inside: avoid !important;
	position: relative;
	background-color: white;
	border: none;
	border-radius: 2rem;
	margin: 0;
	padding: 1vw 1vh;
}


.mini.chart.title {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	padding: 1vw 1vh;

}

.mini-chart-tooltip {
	font-size: 2vw;
}

.mini.chart.name {
	font-size: 3vw;
}

.mini.chart.performance {
	padding-left: 0.3vw;
	font-size: 3vw;
	font-weight: bolder;
}

.mini.chart.performance.separator {
	border-left: thin solid gray;
}


.mode.button.row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin: 7vh 0 0 0;
}


.mode.button.row div {
	font-size: 2vw;
}

.mode.toggle.button {
	font-size: 3vw;
	padding: 1vh 1vw;
	background-color: #0009;
	color: var(--main-color);
	border-radius: 2rem;
}

.mode.toggle.button.highlight {
	padding: 1vh 1vw;
	font-size: 3vw;
	background-color: #000;
	color: var(--main-color);
	border: thick outset var(--main-color);
}


.GT {
	color: blue;
	font-weight: 900;
}

.RGT {
	color: red;
	font-weight: 900;
}