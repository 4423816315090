:root {
  --main-color: #EEB735;
  --main-color-trans: #EEB73533;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 10px;
}
h1 { font-size: 4rem; }
h2 { font-size: 3.5rem; }
h3 { font-size: 3rem; margin-top: .5rem;}
h4 { font-size: 2.5rem; margin-left: 10%; margin-top: .5rem;}
h4 a { font-size: 2.5rem; }
h5 { font-size: 2rem; }
h6 { font-size: 1.5rem; }
html {
  overflow: scroll;
  overflow-x: hidden;
}

body {
  background-color: white;
  background-image: url(img/logo_white-2.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  margin: 0;
  height: 100vh;
  width: 100vw;
  
  color: black;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

body::-webkit-scrollbar {
  display: none;
}

.App {
  position: absolute;
  left: 5%;
  width: 90%;
  top: 0;
  height: 100%;
  text-align: center;
  margin-top: 5rem;
  margin-right: 4rem;
  padding-bottom: 5rem;
  padding-left: 1rem;
  padding-right: 4rem;

  box-sizing: content-box;
}

.brand.header {
  font-size: 3rem;
}

.login {
  margin-top: 5rem;
}

.App-logo {
  position: fixed;
  top: 5px;
  left: 15px;
  height: 50px;
  pointer-events: none;
  box-shadow: 0px 0px 7px 7px rgba(0,0,0,0.75);
  z-index: 2;
}

.App-header {
  color: white;
  pointer-events: none;
}
.App-header svg {
  position: fixed;
  top: 0;
  left: 0;
  width: 500px;
  height: 500px;
}

.App-footer {
  position: fixed;
  bottom: 0;
  right: 0;
}
.App-footer svg {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 500px;
  height: 500px;
  pointer-events: none;
}

.App-footer .navigator {
  position: fixed;
  bottom: 0;
  left: 50vw;
  transform: translateX(-50%);
  z-index: 2;
  border: thin solid yellow;

  /* display: grid; */
  /* grid-template-columns: repeat(3, 1fr); */
  background-color: #000D;
  box-shadow: 0px -3px 7px 7px rgba(0,0,0,0.75);
}

.navigator a {
  float: left;
  font-size: 2rem;
  color: var(--main-color);
}
.navigator a.linked {
  float: left;
  font-size: 2rem;
  background-color: var(--main-color);
  color: black;
}

.chart.radar text.core {
  text-anchor: middle;
  transform: translateY(0.5rem);
}
.chart.radar text.lower {
  text-anchor: end;
  transform: translate(2rem, 0.5rem);
}
.chart.radar text.upper {
  text-anchor: start;
  transform: translate(-2rem, 0.5rem);
}
.chart.radar text.postural {
  text-anchor: middle;
  transform: translateY(0.5rem);
}
.chart.radar text {
  font-size: 3rem;
  vertical-align: middle;
  text-anchor: middle;

  font-weight: 900;
  stroke: black;
  fill: var(--main-color);
}
.chart.radar text.highlight {
  font-size: 3rem;

  font-weight: 900;
  stroke: black;
  fill: white;
}
.chart.radar text:hover {
  stroke: var(--main-color);
}

.chart.radar circle {
  font-size: 2rem;
  vertical-align: middle;
  fill: var(--main-color-trans)
}
.chart.radar circle.highlight {
  font-size: 2rem;
  vertical-align: middle;
  fill: var(--main-color)
}

button.control {
  font-size: 2rem;
  padding: 1rem 2rem 1rem 2rem;
}

.date {
  font-size: 1.5rem;
}

.form-control {
  font-size: 2rem;
  background-color: black;
}
.form-label {
  font-size: 2rem;
}

hr.fuzzy {
  border: 0;
  height: 0; /* Firefox... */
  box-shadow: 0 0 0 2px rgb(200, 200, 200);
}

hr.fuzzy:after {  /* Not really supposed to work, but does */
  content: "\00a0";  /* Prevent margin collapse */
}

td.label {
  text-align: right;
}

.loading.icon {
  position: fixed;
  right: 0;
  /* top: 0; */
  z-index: 9999;
}

.main.content {
  padding-bottom: 5rem;
}

.navbar-brand {
  font-size: 2rem;
  display: flex;
  align-items: left;
}

.nav-item.nav-link {
  font-size: 2rem;
}

.mini.session.chart {
  margin-top: 5rem;
}

.stacked.session.chart {
  /* margin-top: 5rem; */
}

div.stat {
  font-size: 1.5rem;
}

.posture-canvas {
  height: 50vh;
  width: 100%;
}

table.stats {
  
}
table.stats td {
  font-size: 1.8rem;
}

.stat.label {
  font-size: 2rem;
  text-align: right;
}
.stat.value {
  font-size: 2rem;
  padding-left: 1rem;
}

.titlecase {
  text-transform: capitalize;
}

td.value {
  padding-left: 1rem;
}