.mode.button.row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.mode.toggle.button {
    background-color: #383838;
    color: white;
    border: 2px solid #383838;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s ease;
    display: inline-block;
    text-align: center;
    box-sizing: border-box;
}

.mode.toggle.button:hover {
    background-color: #383838;
    box-shadow: 0 0 5px rgba(238, 183, 53, 1);
}

.mode.toggle.button.highlight {
    background-color: #1d1d1d;
    border-color: #1d1d1d;
    box-sizing: border-box;
}

.mode.toggle.button.highlight:hover {
    background-color: #1d1d1d;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
}

.mode.toggle.button:active {
    transform: scale(1);
}
