.machine-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  margin: 5vh 0 0 0;
  background-color: transparent;
  color: #fff;
}

.machine-selector img {
  width: 20vw;
  border: thick ridge rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: filter 0.3s ease;
}

.machine-selector img:hover {
  filter: brightness(0.8);
}

.machine-selector img.highlight {
  border: thick ridge hsl(49, 84%, 57%);
  border-radius: 1rem;
}
